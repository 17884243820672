import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import FormComponent from "../../../components/GeneralElectionForm"

import Background from "../../../components/Background.js"
import { isBlank } from "../../../utils/isBlank"

export default function Home({ data }) {
  return (
    <div className="font-sans">
      <Background>
        <Content data={data} />
      </Background>
    </div>
  )
}

function Content({ data }) {
  const [isWindowBlank, setWindowBlank] = useState(false)
  console.log(data)
  const municipalities = JSON.parse(
    data.allDatoCmsWebsiteFile.edges[0].node.municipality
  ).municipality
  const areas = JSON.parse(data.allDatoCmsWebsiteFile.edges[0].node.area).area
  var defaultMargin = "-my-36 md:-my-56"
  if (isWindowBlank) defaultMargin = ""

  useEffect(() => {
    setWindowBlank(isBlank(window))
  })

  return (
    <div
      className={" md:w-4/5 xl:max-w-7xl " + defaultMargin}
      style={{ fontFamily: "goudy-old-style" }}
    >
      {!isWindowBlank && (
        <a href="https://liikenyt.fi">
          <GatsbyImage
            className="w-36 "
            alt="Liike Nyt logo"
            image={
              data.allDatoCmsWebsiteFile.edges[0].node.logo.gatsbyImageData
            }
          />
        </a>
      )}
      <div className="bg-secondary h-auto shadow-2xl mb-8">
        <FormComponent area={areas} municipality={municipalities}/>
      </div>
    </div>
  )
}

export const query = graphql`
  query Form2Query {
    allDatoCmsWebsiteFile {
      edges {
        node {
          area
          municipality
          logo {
            gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
          }
        }
      }
    }
  }
`
